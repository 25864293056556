import { Accessor, createEffect, createSignal, Show } from "solid-js";
import { ButtonDark } from "~/components/gifting/verification/components";
import { Step } from "~/routes/pluto/login";
import { PhosphorIcon } from "~/widgets/icons";
import { setCookie } from "~/utils/client_cookie";
import PlutoByHubbleSvg from "~/assets/svg_icons/pluto_by_hubble";
import { GiftCardView } from "./gift_card_view_component";

export const PLUTO_ANIMATION_CONSTANTS = {
  TYPING_SPEED: 50,
  COMBO_FADE_DELAY: 600,
  COMBO_CYCLE_INTERVAL: 2000,
  DELETE_DELAY: 500,
} as const;

export const PLUTO_ONBOARDING_DATA = {
  numbers: ["₹1,500", "₹2,500", "₹5,000"],
  numberCombinations: [
    ["₹1,000", "₹400", "₹600"],
    ["₹1,200", "₹400", "₹400"],
    ["₹500", "₹500", "₹1,000"],
  ],
  steps: [
    {
      title: "Let's understand Pluto's magic",
      buttonText: "Let's start",
    },
    {
      title:
        "Gift created by Pluto is a thoughtful bouquet of brands & experiences",
      buttonText: "Next",
    },
    {
      title: "Decide an amount & load it to the gift as the gift balance",
      buttonText: "Next",
    },
    {
      title:
        "Receiver can use the gift balance to shop on one or all brands, the way they like",
      buttonText: "Login",
    },
  ],
};

interface PlutoOnboardingProps {
  currentStep: Accessor<Step>;
  onStepChange: (step: Step) => void;
  onComplete: () => void;
}

export default function PlutoOnboardingComponent(props: PlutoOnboardingProps) {
  const val = props.currentStep();
  const [delayedStep, setDelayedStep] = createSignal<Step>(val);

  const handleNext = () => {
    if (props.currentStep() === 3) {
      setCookie({
        key: "is_pluto_onboarding_complete",
        value: "true",
        expiryInMinutes: 30,
      });

      props.onComplete();
      return;
    }
    props.onStepChange((props.currentStep() + 1) as Step);
  };

  const handlePrev = () => {
    props.onStepChange((props.currentStep() - 1) as Step);
  };

  createEffect(async () => {
    let val = props.currentStep();
    await new Promise((resolve) => setTimeout(resolve, 200));
    setDelayedStep(val);
  });

  return (
    <>
      <div class="absolute bottom-0 left-0 right-0 top-0 z-[0] bg-plutoOnboardingBgMob bg-cover bg-no-repeat lg:z-0 lg:bg-plutoOnboardingBgLG"></div>
      <div class="bottom-0 left-0 right-0 top-0 z-[2] lg:absolute lg:bg-plutoOnboardingGradientLG lg:bg-cover lg:bg-no-repeat"></div>
      <Show when={props.currentStep() !== 0}>
        <div
          onClick={handlePrev}
          class="absolute left-10 top-10 z-50  hidden cursor-pointer lg:flex"
        >
          <PhosphorIcon
            name="arrow-left"
            fontSize={24}
            class=" h-10 w-10  items-center justify-center rounded-full border border-basePrimaryDark bg-black p-2 text-white "
          />
        </div>
      </Show>
      <div class="dark flex h-full w-full flex-col items-center justify-start   ">
        <div class="z-10 flex h-[60px] w-full items-center justify-center ">
          <PlutoByHubbleSvg />
        </div>
        <div class="mt-2"></div>
        <GiftCardView step={props.currentStep()} />
        <div class="mt-8"></div>
        <div class={`z-[2] w-full  px-5 pb-4 lg:max-w-[352px]  lg:pb-[52px]`}>
          <div
            class="flex flex-col items-center justify-center  transition-all  duration-300"
            classList={{
              "opacity-0": props.currentStep() !== delayedStep(),
              "opacity-100": props.currentStep() === delayedStep(),
            }}
          >
            <StepIndicator currentStep={props.currentStep()} />

            <div class=" flex h-[90px] max-w-[335px] flex-col items-center justify-center ">
              <h1
                class=" text-center font-jakartaSans font-semibold leading-[125%] text-white "
                classList={{
                  "text-[32px] tracking-[-0.32px]": delayedStep() == 0,
                  "text-[24px] tracking-[-0.24px]": delayedStep() > 0,
                }}
              >
                {PLUTO_ONBOARDING_DATA.steps[delayedStep()].title}
              </h1>
            </div>
          </div>

          <NavigationControls
            currentStep={props.currentStep()}
            totalSteps={PLUTO_ONBOARDING_DATA.steps.length}
            onNext={handleNext}
            onPrev={handlePrev}
            buttonText={
              PLUTO_ONBOARDING_DATA.steps[props.currentStep()].buttonText
            }
          />
        </div>
      </div>
    </>
  );
}

interface StepIndicatorProps {
  currentStep: number;
}

function StepIndicator(props: StepIndicatorProps) {
  return (
    <p class="text-center text-smallBold tracking-[1.1px] text-textNormal">
      {props.currentStep + 1}/4
    </p>
  );
}

interface NavigationControlsProps {
  currentStep: number;
  totalSteps: number;
  onNext: () => void;
  onPrev: () => void;
  buttonText: string;
}

function NavigationControls(props: NavigationControlsProps) {
  return (
    <div class="mt-4 flex gap-2.5">
      {props.currentStep !== 0 && (
        <PhosphorIcon
          name="arrow-left"
          fontSize={24}
          class="flex h-11 w-11 cursor-pointer items-center justify-center rounded-full border border-basePrimaryDark p-2 text-white lg:hidden"
          onClick={props.onPrev}
        />
      )}
      <ButtonDark
        class="z-[2] flex-1 !rounded-full"
        onClick={props.onNext}
      >
        {props.buttonText}
      </ButtonDark>
    </div>
  );
}
