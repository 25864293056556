import { setCookie } from "~/utils/client_cookie";

import { clientRepo } from "~/server/apis/client_repo";
import { config } from "~/data/config";

export default class PlutoAuth {
  pluto_client_id = "pluto";
  pluto_client_secret = "2tHXf1AzwSs3";

  sid_key = "sid";

  async verifyOtp(props: {
    phoneNumber: string;
    otp: string;
    otpToken: string;
  }) {
    return await clientRepo.verifyPartnerOTP(
      {
        clientId: this.pluto_client_id,
        clientSecret: this.pluto_client_secret,
      },
      {
        phoneNumber: props.phoneNumber,
        otp: props.otp,
        otpToken: props.otpToken,
      },
      {
        isUnauthenticated: true,
      }
    );
  }

  async sendOtp(phoneNumber: string) {
    return await clientRepo.generatePartnerOTP(
      {
        clientId: this.pluto_client_id,
        clientSecret: this.pluto_client_secret,
      },
      {
        phoneNumber: phoneNumber,
      },
      {
        isUnauthenticated: true,
      }
    );
  }

  setSessionId(sid: string) {
    setCookie({
      key: this.sid_key,
      value: sid,
      expiryInMinutes: config.sesssionDurationInMinutes.pluto,
    });
  }
}
