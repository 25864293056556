import { Spacer } from "~/widgets/spacer";
import { CollectPhoneForm } from "./collect_phone_form";
import { createSignal, Match, Show, Switch } from "solid-js";
import { CollectOtp } from "./collect_otp_form";
import { useLocation, useNavigate } from "@solidjs/router";
import { VerifyPartnerOTPResponse } from "~/server/types/brand";
import PlutoAuth from "../pluto_auth";
import { OnboardingModal } from "./onboarding_modal";
import { Appbar } from "../app_bar";
import { CollectNameForm } from "./collect_name_form";
import { clientRepo } from "~/server/apis/client_repo";
import { PhosphorIcon } from "~/widgets/icons";
import { Gifts } from "~/server/types/pluto";
import { VerificationPlatform } from "~/server/types/auth";
import { generateDeviceVerificationToken } from "~/utils/common";

interface PlutoLoginProps {
  onBack: () => void;
  step: LoginStepState | undefined;
}

export type LoginStepState = "phone" | "otp" | "name" | "about_pluto";

export default function PlutoLoginComponent(props: PlutoLoginProps) {
  const [step, setStep] = createSignal<LoginStepState>(props.step ?? "phone");
  const [otpToken, setOtpToken] = createSignal<string>("");
  const [phone, setPhone] = createSignal<string>("");
  const navigate = useNavigate();
  const plutoAuth = new PlutoAuth();

  const getLatestGifts = (
    gifts: Gifts[]
  ): {
    latestPaid: Gifts | null;
    latestNotPaid: Gifts | null;
  } => {
    let latestPaid: Gifts | null = null;
    let latestNotPaid: Gifts | null = null;

    gifts.forEach((gift) => {
      const giftDate = new Date(gift.generatedOn);
      if (gift.paymentStatus === "PAID") {
        if (!latestPaid || giftDate > new Date(latestPaid.generatedOn)) {
          latestPaid = gift;
        }
      } else if (gift.paymentStatus === "NOT_PAID") {
        if (!latestNotPaid || giftDate > new Date(latestNotPaid.generatedOn)) {
          latestNotPaid = gift;
        }
      }
    });

    return { latestPaid, latestNotPaid };
  };

  return (
    <div class="dark flex h-full w-full flex-col bg-plutoLoginBgMob   bg-cover bg-no-repeat px-4 lg:bg-plutoLoginBgLG">
      <div class={`flex flex-row items-center justify-center py-4 lg:p-0`}>
        <PhosphorIcon
          name="arrow-left"
          fontSize={24}
          class="absolute left-4 top-4 flex h-9 w-9 cursor-pointer items-center justify-center rounded-full border border-basePrimaryDark bg-black p-2 text-white lg:left-10 lg:top-10 lg:h-10 lg:w-10"
          onClick={() => {
            if (step() == "phone") {
              props.onBack();
            } else if (step() == "about_pluto") {
              setStep("name");
            } else {
              setStep("phone");
            }
          }}
        />
        <div class="lg:hidden">
          <Appbar class="h-[36px] w-[151px]" />
        </div>
      </div>
      <div
        class={`${step() === "about_pluto" ? "flex-1" : "lg:mt-[15.5rem] lg:flex-none"} flex-col items-center justify-center gap-4 lg:flex`}
      >
        <div class="hidden lg:block">
          <Appbar class="w-[151px]" />
        </div>
        <div class="flex h-full w-full flex-col lg:h-auto lg:w-[400px] lg:rounded-[20px] lg:border lg:border-basePrimaryMedium lg:bg-black lg:px-7 lg:py-8">
          <Switch>
            <Match when={step() === "phone"}>{buildCollectPhoneForm()}</Match>
            <Match when={step() === "otp"}>{buildCollectOtpForm()}</Match>
            <Match when={step() === "name"}>{buildCollectNameForm()}</Match>
            <Match when={step() === "about_pluto"}>{<OnboardingModal />}</Match>
          </Switch>
        </div>
      </div>
    </div>
  );

  function buildCollectPhoneForm() {
    return (
      <CollectPhoneForm
        type="pluto"
        onValidPhoneNumberSubmitted={(phoneNumber, otpToken) => {
          setOtpToken(otpToken);
          setPhone(phoneNumber);
          setStep("otp");
        }}
        submitPhoneNumber={async (phoneNumber) => {
          const response = await plutoAuth.sendOtp(phoneNumber);
          return {
            otpToken: response.otpToken,
          };
        }}
      />
    );
  }

  function buildCollectOtpForm() {
    return (
      <CollectOtp<VerifyPartnerOTPResponse>
        type="pluto"
        onVerificationSuccess={async (response) => {
          plutoAuth.setSessionId(response.sessionId);
          try {
            await clientRepo.verifyUser(
              await generateDeviceVerificationToken(),
              VerificationPlatform.FingerPrintJs
            );
          } catch (error) {
            // nothing on error: backend told to hit & forget
          }

          if (response.isNewUser) {
            setStep("name");
          } else {
            const conversations = await clientRepo.getAllConversations();

            const gifts = await clientRepo.getAllGifts();

            const { latestPaid, latestNotPaid } = getLatestGifts(gifts);

            const inProgressConversation = conversations.find(
              (conversation) => conversation.status === "IN_PROGRESS"
            );

            if (latestNotPaid) {
              navigate("/pluto/create/new", {
                state: { id: latestNotPaid.id },
                replace: true,
              });
            } else if (latestPaid) {
              navigate("/pluto/create/new", {
                state: { id: latestPaid.id },
                replace: true,
              });
            } else if (inProgressConversation) {
              navigate(`/pluto/create/chat/${inProgressConversation.id}`, {
                replace: true,
              });
            } else {
              navigate("/pluto/create/new", { replace: true });
            }
          }
        }}
        otpToken={otpToken()}
        phoneNumber={phone()}
        resendOtp={async (number) => {
          const response = await plutoAuth.sendOtp(number);
          setOtpToken(response.otpToken);
          return {
            otpToken: response.otpToken,
          };
        }}
        submitOtp={async (props) => {
          return await plutoAuth.verifyOtp({
            phoneNumber: phone(),
            otp: props.otp,
            otpToken: props.otpToken,
          });
        }}
        onClickEditPhoneNumber={() => {
          setStep("phone");
        }}
      />
    );
  }

  function buildCollectNameForm() {
    return (
      <CollectNameForm
        onSuccess={() => {
          setStep("about_pluto");
        }}
        submitName={async (firstName, lastName) => {
          return await clientRepo.updateUserProfile({
            firstName,
            lastName,
            phoneNumber: phone(),
          });
        }}
      />
    );
  }
}
