import { createEffect, createSignal, Show } from "solid-js";
import { ButtonDark } from "~/components/gifting/verification/components";
import { createJob } from "~/utils/job";
import { Spacer } from "~/widgets/spacer";

export function CollectNameForm(props: {
  submitName: (firstName: string, lastName: string) => void;
  onSuccess: () => void;
}) {
  const [name, setName] = createSignal<string>("");
  const [errorMessage, setErrorMessage] = createSignal<string | undefined>("");

  const [isFieldActive, setIsFieldActive] = createSignal(false);

  const submitNameJob = createJob({
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    initialJob: async () => {
      if (!name()) {
        throw new Error("Please enter a name");
      }
      setName(name().trim());
      return props.submitName(
        name().split(" ")[0],
        name().split(" ").slice(1).join(" ")
      );
    },
    successCallback: async () => {
      return props.onSuccess();
    },
  });

  let nameInput: HTMLInputElement | undefined;
  createEffect(() => {
    nameInput?.focus();
  });

  return (
    <div>
      <div>
        {buildTitleAndSubtitle()}
        <div class="h-5 sm:h-6" />
        {buildInputField()}
        <Spacer height={32} />
        <ButtonDark
          class="!rounded-full"
          onClick={() => {
            submitNameJob.run();
          }}
          isLoading={() => submitNameJob.jobState() == "running"}
        >
          Let's start
        </ButtonDark>
      </div>
    </div>
  );

  function buildTitleAndSubtitle() {
    return (
      <div class="mt-5 flex flex-col items-start text-baseTertiaryLight lg:mt-0">
        <span class="text-smallBold uppercase">A little about you</span>
        <Spacer height={6} />
        <span class="text-center text-h4 ">Your name</span>
      </div>
    );
  }

  function buildInputField() {
    return (
      <div class="flex flex-col">
        <div
          class="rounded-lg border px-3 py-[12px] transition-colors"
          classList={{
            "border-baseTertiaryLight": isFieldActive(),
            "border-baseSecondaryMedium": !isFieldActive(),
            "border-errorDark": !!errorMessage(),
          }}
        >
          <form
            class="text flex text-bold text-baseTertiaryLight"
            onSubmit={(e) => {
              e.preventDefault();
              submitNameJob.run();
            }}
          >
            <input
              class="flex-1 bg-transparent text-[16px] font-semibold text-baseTertiaryLight placeholder:text-normal placeholder:text-textNormal"
              ref={nameInput}
              placeholder=" Your name"
              value={name()}
              onInput={(e) => setName(e.currentTarget.value)}
              type="text"
              onFocus={() => setIsFieldActive(true)}
              onBlur={() => setIsFieldActive(false)}
            />
          </form>
        </div>
        <Show when={errorMessage()}>
          <Spacer height={4} />
          <span class="text-medium  text-errorDark">{errorMessage()}</span>
        </Show>
      </div>
    );
  }
}
